import { request } from "./index";

/**
 * 获取推广文案
 */
 export function getTaskContentList(data) {
    return request({
        method: "post",
        url: "task/TaskContentList",
        data
    })
}