<template>
  <div class="script" ref="parentBox">
    <div class="script-top">
      <span class="st-selectChart" @click="show = true">{{ info.title }} <i class="font_family icon-31xiala"></i></span>
    </div>
    <div class="content-bg">
      <div class="scipt-content" ref="childBox" v-html="content"></div>
    </div>
    <div class="script-options" v-if="appPlatform.length > 0">
      <div class="so-top">
        <van-button size="small" v-for="item in appPlatform" @click="selectAccountFun(item)" :key="item.id"
          class="options-btn" type="primary">推广{{ $utils.platform_type_filter(item.type) }}
        </van-button>
      </div>
    </div>
  </div>
  <van-popup v-model:show="show" @closed="$store.dispatch('setTabBarDisplay', true)" round position="bottom">
    <van-picker ref="picker" title="选择文案" value-key="title" :columns="actions" @cancel="clearPicker"
      @confirm="onSelect" />
  </van-popup>
  <SelectAccount v-model:overlayShow="overlayShow" v-model:ids="selectAccountIds" @result="showTips" />
  <van-overlay :show="copyTips" @click="copyTips = false">
    <div class="tipsBox" @click.stop>
      <div class="tips">
        <van-icon name="cross" class="tips-close" @click="copyTips = false" />
        <div class="tips-title">任务领取成功</div>
        <div class="tips-content">
          {{ tipsText }}
        </div>
        <div class="copyText" @click="jumpExtension">去使用文案</div>
        <div class="copyTips" @click="copyTips = false">继续推广书籍</div>
      </div>
    </div>
  </van-overlay>
  <!-- <jumpBinding ref="jumpBinding" /> -->

</template>

<script>
import { getTaskContentList } from '@/api/script.js'
import { getTaskList } from '@/api/task.js'
import SelectAccount from './components/selectAccount.vue'
import { getUserInfo } from '@/api/information.js'
// import jumpBinding from '@/components/jumpBinding.vue'

export default {
  name: 'scriptDom',
  components: {
    // jumpBinding,
    SelectAccount
  },
  data() {
    return {
      actions: [],
      info: {},
      text: '',
      show: false,
      content: '',
      appPlatform: [],
      overlayShow: false,
      selectAccountIds: {},
      bookId: '',
      copyTips: false,
      tipsText: '',
      appName: '',
      // 打开过一次绑定收款信息后就不再显示了
      onceOpen: false
    }
  },
  async beforeMount() {
    document.title = this.$route.query.bookName || '推广书籍'
    this.bookId = this.$route.query.id
    let res = await getTaskContentList({ bookId: this.bookId })
    this.info = res.list[0]
    this.setContentText()
    this.actions = res.list.map(item => {
      item.name = item.title
      return item
    })
    let { list } = await getTaskList({ bookId: this.bookId })
    this.appPlatform = list[0].appPlatform
  },
  methods: {
    onSelect(item) {
      this.show = false
      this.info = JSON.parse(JSON.stringify(item))
      this.$refs.childBox.scrollTop = 0
      this.setContentText()
      this.stopFlag = true
    },
    setContentText() {
      let textArr = this.info.content.split('\n\n')
      let text = ''
      textArr.forEach(element => {
        text += `<p>${element}</p>`
      })
      this.content = text
    },
    clearPicker() {
      this.$refs.picker.setColumnIndex(0)
      this.show = false
    },
    async selectAccountFun(item) {
      // 判断弹出提示完善签约弹窗,暂时注释掉
      // let user = await getUserInfo()
      // if (user.apply_settlement === 0 && !this.onceOpen) {
      //   this.$refs.jumpBinding.showPopup()
      //   this.onceOpen = true
      // } else {
      this.overlayShow = true
      this.selectAccountIds = {
        // 应用平台id
        appPlatformId: item.id,
        // 图书id
        bookId: this.bookId
        // }
      }
    },
    showTips(val) {
      this.appName = val.app_name
      this.copyTips = true
      this.tipsText = `关注公众号“${val.app_name}”，回复“${val.reply_keywords}”`
      this.$store.commit('setTaskId', this.bookId)
    },
    jumpExtension() {
      this.copyTips = true
      this.$router.replace({
        path: '/scriptExtension',
        query: {
          id: this.$route.query.id,
          appName: this.appName
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ p {
  padding: 10px;
  line-height: 50px;
  font-size: 32px;
  text-align: justify;
}
.script {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  background: #f8f8f8;
  .script-top {
    width: 100%;
    padding: 14px 40px;
    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    color: #fe764b;
    .st-selectChart {
      border: 1px solid #fe764b;
      padding: 4px 14px;
      border-radius: 40px;
    }
    i {
      font-size: 26px;
    }
  }
  .content-bg {
    flex: 1;
    overflow: hidden;
    background: #f8f8f8;
    padding: 0px 30px;
    .scipt-content {
      height: 100%;
      overflow: scroll;
      background: white;
      text-align: left;
      padding: 20px;
      padding-bottom: 40%;
      p {
        padding: 10px;
        line-height: 50px;
        font-size: 32px;
        letter-spacing: 2px;
      }
    }
  }
  .script-options {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 12px;
    width: 100%;
    padding: 20px 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .so-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      margin-bottom: 12px;
    }
    .so-tips {
      color: #999999;
      font-size: 20px;
      margin-left: 200px;
    }

    .options-btn {
      border-radius: 60px;
      white-space: nowrap;
      margin-right: 30px;
      border: none;
      padding: 0px 20px;
      background: none;
      color: #fff;
      background-image: linear-gradient(
        to right,
        #ff9f71,
        #ff9567,
        #ff8b5d,
        #fe8154,
        #fe764b
      ) !important;
    }
    .options-slider {
      position: relative;
      flex: 1;
      color: #fff;
      font-weight: bold;
      font-size: 28px;
    }
  }
  .van-slider__bar {
    overflow: hidden;
    background-image: linear-gradient(
      to right,
      #ff9f71,
      #ff9567,
      #ff8b5d,
      #fe8154,
      #fe764b
    );
  }
  .custom-button {
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    margin-right: 80px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}
/* 样式和extension基本一致，放在index.vue中，这里做些微调 */
.script-top {
  justify-content: center !important;
}
.so-top {
  justify-content: flex-end !important;
}
.tipsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .tips {
    position: relative;
    width: 655px;
    border-radius: 30px;
    background-color: #fff;
    padding: 30px 40px 20px;
    .tips-close {
      position: absolute;
      top: 28px;
      right: 30px;
      font-size: 44px;
      color: #c8c9cc;
    }
    .tips-content {
      margin: 60px 90px;
      font-size: 32px;
      text-align: center;
      letter-spacing: 1px;
    }
    .copyText {
      font-size: 30px;
      color: white;
      background: #fe774c;
      border-radius: 10px;
      padding: 12px 40px;
    }
    .copyTips {
      color: #999999;
      font-size: 22px;
      color: #ff784c;
      margin-top: 20px;
    }
  }
}
</style>
