<template>
  <van-popup
    :lock-scroll="true"
    :show="overlayShowFlag"
    @click.stop="show = false"
    class-name="OverlayStyle"
  >
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="block-title">立即推广</div>
        <span class="block-span">请选择要推广的渠道</span>
        <van-divider />
        <div class="block-select block-null" v-if="platform.length == 0">
          在“信息管理-渠道绑定”中添加渠道账号后，才可在此处选择
        </div>
        <div class="block-select" v-else>
          <van-radio-group
            v-model="promotionPlatformId"
            checked-color="#fe774c"
          >
            <van-radio
              class="mb-20"
              border
              v-for="item in platform"
              :key="item.id"
              :name="item.id"
            >
              <span class="bs-title">{{ item.name }}</span>
            </van-radio>
          </van-radio-group>
        </div>
        <van-button
          v-if="platform.length > 0"
          type="primary"
          class="block-submit"
          size="small"
          @click="submit"
          >领取推广任务</van-button
        >
        <van-button
          v-else
          type="primary"
          class="block-submit"
          size="small"
          @click="$router.push('/bindingChannel')"
          >去添加渠道账号</van-button
        >
        <div class="block-tips" v-if="platform.length > 0">
          绑定渠道账号后才可选择
          <span style="color: #0eb4f5" @click="$router.push('/bindingChannel')"
            >马上去绑定</span
          >
        </div>
        <i class="font_family icon-cha block-close" @click="close"></i>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Toast } from 'vant'
import { submitTask } from '@/api/task'
import { getPromotionPlatformFilter } from '@/api/public'
import { Dialog } from 'vant'
export default {
  name: 'addExtension',
  props: {
    overlayShow: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Object,
      default: {}
    }
  },
  async mounted() {
    this.platform = await getPromotionPlatformFilter()
  },
  watch: {
    async overlayShow(newVal) {
      this.overlayShowFlag = newVal
    }
  },
  data() {
    return {
      overlayShowFlag: false,
      platform: [],
      promotionPlatformId: '',
      columns: []
    }
  },
  methods: {
    async submit() {
      if (!this.promotionPlatformId) {
        return Toast.fail('请选择推广渠道')
      }
      let data = Object.assign(
        { promotionPlatformId: this.promotionPlatformId },
        this.ids
      )
      let res = await submitTask(data)
      this.$emit('update:overlayShow', false)
      this.$emit('result', res)
    },
    close() {
      this.$emit('update:overlayShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: relative;
  width: 622px;
  border-radius: 18px;
  padding: 0px 18px 20px;
  background-color: #fff;
  .block-title {
    font-size: 32px;
    font-weight: bold;
    margin: 20px 0px 8px;
  }
  .block-span {
    font-size: 24px;
    color: #666;
  }
  .block-submit {
    margin: 35px 29px 20px;
    width: 526px;
    background: #fe774c;
    color: white;
    font-size: 30px;
    border: none;
    letter-spacing: 2px;
    border-radius: 6px;
  }
  .block-close {
    position: absolute;
    padding: 20px;
    top: 16px;
    right: 16px;
    font-size: 28px;
  }
  .block-null {
    display: flex;
    align-items: center;
    text-align: left;
    color: #888;
  }
  .block-select {
    padding: 0px 30px;
    height: 320px;
    overflow-y: auto;
    .van-radio-group {
      display: flex;
      flex-wrap: wrap;
      .van-radio {
        width: 50%;
        padding-bottom: 10px;
      }
    }
    .bs-title {
      width: 150px;
      font-weight: bold;
      font-size: 30px;
      text-align: left;
    }
  }
  .block-tips {
    font-size: 20px;
    color: #999;
  }
}
/deep/ .van-radio__label {
  display: flex;
  align-items: center;
}
.bb {
  overflow: scroll;
  .aa {
    padding: 20px 0px;
  }
}
</style>
